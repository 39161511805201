<template>
  <span class="brand-logo">
    <b-img
      :src="appLogoImage"
      alt="logo"
    />
  </span>
</template>

<script>
import { BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style lang="scss">
.brand-logo img {
  max-width: 150px;
}
</style>
